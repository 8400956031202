<template>
  <output
    dir="ltr"
    tabindex="0"
    role="slider"
    aria-live="off"
    aria-valuemin="1"
    aria-valuemax="5"
    :class="computedClasses"
    :aria-valuenow="roundedRating"
  >
    <span
      v-for="n in roundedRating"
      :key="`full-${n}`"
      class="z-rating-star flex-grow-1 z-rating-star-full"
    >
      <span
        class="z-rating-icon"
        @click="handleClick(n)"
      >
        <Fa
          :icon="['fas', 'star']"
          style="color: #276df8"
        />
      </span>
    </span>
    <span
      v-for="n in emptyStars"
      :key="`empty-${n}`"
      class="z-rating-star flex-grow-1 z-rating-star-empty"
    >
      <span
        class="z-rating-icon"
        @click="handleClick(roundedRating + n)"
      >
        <Fa
          :icon="['fas', 'star']"
          style="color: #d4e2fe"
        />
      </span>
    </span>
  </output>
</template>

<script setup lang="ts">
import type { RatingSize } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  size?: RatingSize
  value?: number
  inline?: boolean
  noBorder?: boolean
  readonly?: boolean
}>(), {
  size: 'md',
  value: 3,
})

const emit = defineEmits<{
  change: [value: number]
}>()

const computedClasses = computed(() => {
  return [
    'z-rating',
    'form-control',
    'z-rating--free',
    'align-items-center',
    `form-control-${props.size}`,
    {
      'd-inline-flex': props.inline,
      'border-0': props.noBorder,
      'readonly': props.readonly,
    },
  ]
})

const roundedRating = computed(() => {
  return validateRating(props.value)
})

const emptyStars = computed(() => 5 - roundedRating.value)

function handleClick(value: number) {
  if (!props.readonly) {
    emit('change', validateRating(value))
  }
}

function validateRating(rating: number) {
  const value = Math.round(rating)
  if (value > 5) return 5
  if (value < 0) return 0

  return value
}
</script>
